import { formatPercentages } from 'utils';

export const themeCategories = [
    {
        key: '',
        value: 'Asset Class',
        aggregateField: 'asset_class',
        responseField: 'asset_class',
    },
    {
        key: 'CFRA Category 1',
        value: 'Category 1',
        isdefault: true,
        aggregateField: 'cfra_level_1',
        responseField: 'cfra_level_1',
    },
    {
        key: 'CFRA Category 2',
        value: 'Category 2',
        aggregateField: 'cfra_level_2',
        responseField: 'level_2_name',
    },
];

export const performanceOrderFields = new Map([
    ['YTD', 'split_and_dividend_ytd_return'],
    ['1M', 'return_split_and_dividend_one_month'],
    ['3M', 'return_split_and_dividend_three_month'],
    ['1Y', 'return_split_and_dividend_one_year'],
]);

export const dataLimitForAssetCategory: number = 10;

export const flowOrderFields = new Map([
    ['YTD', 'flows_to_assets_ratio_ytd'],
    ['1M', 'flows_to_assets_ratio_one_month'],
    ['3M', 'flows_to_assets_ratio_three_month'],
    ['1Y', 'flows_to_assets_ratio_one_year'],
]);

export function getChangeEvents(setCurrentActiveTab: (value: any) => void, setCurrentPeriod: (value: any) => void) {
    return {
        handleTabChange: (event: React.SyntheticEvent, newValue: number) => {
            setCurrentActiveTab(newValue);
        },
        handlePeriodChange: (event: any) => {
            setCurrentPeriod(event.target.value as string);
        },
    };
}

export function mappingFunction(orderFields: Map<string, string>) {
    return function mapFunction<T>(input: { value: T; currentPeriod: string }) {
        return formatPercentages(input.value[orderFields.get(input.currentPeriod) as keyof T] as number);
    };
}

export function categoriesMapFunction<T>(input: { value: any; currentActiveTab: any }) {
    return `${input.value[themeCategories[input.currentActiveTab].responseField as keyof T] as string} (${input.value.composite_ticker})`
}



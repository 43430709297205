import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useState } from 'react';

const Tooltip = ({ children, ...rest }: TooltipProps) => {
    const [renderTooltip, setRenderTooltip] = useState(false);
    return (
        <div onMouseEnter={() => !renderTooltip && setRenderTooltip(true)} className='display-contents'>
            {!renderTooltip && children}
            {renderTooltip && (
                <MUITooltip {...rest} arrow>
                    {children}
                </MUITooltip>
            )}
        </div>
    );
};

export default Tooltip;

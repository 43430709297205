import { StyledTab, StyledTabs } from 'components/layout';
import { ThemeProvider, styled } from '@mui/material/styles';
import { fontFamilies } from 'utils';
import Tooltip from 'components/dataDisplay/Tooltip';
import { tooltipThemeV2 } from 'components/themes/theme';

const StyledChartTabs = styled(StyledTabs)(({ theme }) => ({
    '& div.MuiTabs-scroller': {
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
            '& .MuiButtonBase-root': {
                paddingTop: '0px',
            },
        },
    },
    '& .MuiTabs-indicator': {
        height: 2,
    },
    '& .MuiTab-root': {
        margin: '0px 24px 0px 0px',
        minWidth: '0px',
    },
}));

const StyledChartTab = styled(StyledTab)(({ theme }) => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '14px',
}));

type ChartTabsProps = {
    currentActiveTab?: number;
    tabs: Array<{ key: string; value: string }>;
    handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export function ChartTabs(chartTabsProps: ChartTabsProps) {
    return (
        <ThemeProvider theme={tooltipThemeV2}>
            <StyledChartTabs
                value={chartTabsProps.currentActiveTab || 0}
                onChange={chartTabsProps.handleTabChange}
                aria-label='widget tabs'>
                {chartTabsProps.tabs.map((item, index) => {
                    let tabLabel =
                        item.key !== '' ? (
                            <Tooltip title={item.key}>
                                <span>{item.value}</span>
                            </Tooltip>
                        ) : (
                            item.value
                        );

                    return <StyledChartTab label={tabLabel} value={index} key={'Tab' + index} />;
                })}
            </StyledChartTabs>
        </ThemeProvider>
    );
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { DocumentResult } from '../types/opensearch';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

type DocumentDialogProps = {
    document: DocumentResult | undefined;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DocumentDialog({ document, open, setOpen }: DocumentDialogProps) {
    //const [open, setOpen] = React.useState(false);

    const replaceHighlight = (text: string | undefined) => {
        if (text) {
            let newText = text
                .replace(/<@highlight@>/g, "<span class='highlighted-text'>")
                .replace(/<\/@highlight@>/g, '</span>');

            return newText.replace(/\s*Page 1 of \d\s*/, '');
        }

        return '';
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <BootstrapDialog
                sx={{ marginTop: '50px' }}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={handleClose}
                aria-labelledby='customized-dialog-title'
                open={open}>
                <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
                    {document?._source.title}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography
                        gutterBottom
                        className='search-results-panel'
                        sx={{ whiteSpace: 'pre-wrap' }}
                        dangerouslySetInnerHTML={{
                            __html:
                                replaceHighlight(document?.highlight?.raw_text[0]) ||
                                replaceHighlight(document?._source?.raw_text) ||
                                '',
                        }}></Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

import { Environments } from 'utils/enums';

export const MSA_MICROSERVICES_URL = process.env.REACT_APP_MSA_MICROSERVICES;
export const MSA_ATHENA_URL = process.env.REACT_APP_MSA_ATHENA;
export const OPENSEARCH_URL = process.env.REACT_APP_OPENSEARCH_URL as string;
export const POLICY_SEARCH_URL = process.env.REACT_APP_POLICY_SEARCH_URL as string;
export const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL as string;
export const API_URL =
    (process.env.REACT_APP_API_URL as string) +
        (process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH_OVERRIDE ||
        process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH_OVERRIDE === ''
            ? process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH_OVERRIDE
            : process.env.REACT_APP_API_BACKEND_ENVIRONMENT_PATH) || '';
export const COGNITO_REFRESH_TOKEN_DELTA = Number(process.env.REACT_APP_COGNITO_REFRESH_TOKEN_DELTA);
export const COGNITO_ENVIRONMENT = process.env.REACT_APP_COGNITO_ENVIRONMENT as string;
export const ENVIRONMENT = process.env.NODE_ENV;
export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;
export const IS_STRICT_MODE = ENVIRONMENT_NAME !== Environments.Production && ENVIRONMENT_NAME !== Environments.UAT;
export const MSA_API_URL = process.env.REACT_APP_MSA_API_URL as string;
export const ENABLE_MSW = (process.env.REACT_APP_ENABLE_MSW === 'true') as boolean;
export const ENABLE_RUM = (process.env.REACT_APP_ENABLE_RUM === 'true') as boolean;
export const RUM_GUEST_ROLE_ARN = process.env.REACT_APP_RUM_GUEST_ROLE_ARN as string;
export const RUM_IDENTITY_POOL_ID = process.env.REACT_APP_RUM_IDENTITY_POOL_ID as string;
export const RUM_ENDPOINT = process.env.REACT_APP_RUM_ENDPOINT as string;
export const RUM_APPLICATION_ID = process.env.REACT_APP_RUM_APPLICATION_ID as string;
export const RUM_APPLICATION_REGION = process.env.REACT_APP_RUM_APPLICATION_REGION as string;

import { useMediaQuery } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { Page } from 'analytics/Page';
import { ChartDescription } from 'components/Chart/Chart';
import { TableExportMenus } from 'components/Chart/ExportMenus';
import { AgGrid, columnDefs, getAgGridFormatter } from 'components/DataGrid/AgGrid';
import { exportExcel } from 'components/excelExport/export';
import { ExcelMetadata, ExcelTable } from 'components/excelExport/type';
import { ETFCard, ItemHeader } from 'components/layout';
import Grid from 'components/layout/Grid';
import { ColumStyle } from 'components/layout/types';
import { breakpointsTheme } from 'components/themes/theme';
import { getKeyAssetClassesData, getLevel2Flows, getLevel2Performance } from 'features/marketTrends/api/market';
import { ETFFlowsToAssetThemesAndFactorsData, KeyAssetClassesData } from 'features/marketTrends/types/research';
import { useLayoutEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { useOutletContext } from 'react-router-dom';
import { ValueTypes } from 'utils/valuesFormatter';
import { ContextType, MarketTrend, MarketTrendsDetailsProps } from '../types/market';
import {
    tickerDisplayName as keyAssetClassesTickerDisplayName,
    tickerExchanges as keyAssetClassesTickerExchanges,
} from './charts/KeyAssetClasses';
import {
    tickerDisplayName as SectorPerformanceTickerDisplayName,
    tickerExchanges as SectorPerformanceTickerExchanges,
} from './charts/SectorPerformance';

export function MarketTrendsDetails({ label }: MarketTrendsDetailsProps) {
    const isBelowLg = useMediaQuery(breakpointsTheme.breakpoints.down('lg'));
    let columnDefs: columnDefs = null;
    let result: UseQueryResult<any[]>;

    const getRenderer = (type: 'cfraId' | 'ticker/exchange') => (props: ICellRendererParams) => {
        const value = props.valueFormatted || props.value;
        return (
            <a
                href={
                    type === 'cfraId'
                        ? `/etf/cfra-id/${props.data.cfra_id || props.data.id}`
                        : `/etf/ticker/${props.data.composite_ticker}/exchange/${props.data.exchange_code}`
                }
                target={'_blank'}
                rel='noreferrer'
                className='company-details-url'>
                {value}
            </a>
        );
    };

    function getMinWidthForHeader(headerName?: string): number {
        switch (headerName) {
            case 'YTD Return':
                return 125;
            case '1 Month Return':
            case '1 Year Return':
            case '1 Month Flows':
            case '1 Year Flows':
            case 'Sector':
                return 135;
            case '3 Month Return':
            case '3 Month Flows':
                return 145;
            case 'CFRA Category 2':
                return 150;
            case '3 Month Flows to Asset':
                return 175;
            case 'Number of ETFs':
            case 'Asset Class':
                return 125;
            default:
                return 120;
        }
    }

    const getCustomlyFlexColumnWidth = (
        columnName: string,
        minWidth: number,
        maxWidth: number,
        allColumnsNames: Array<string>,
    ): number | undefined => {
        let width: number | undefined;
        const customlyFlexibleColumns = ['CFRA Category 2', 'ETF Name'];
        if (customlyFlexibleColumns.includes(columnName)) {
            const columnsIntersection = allColumnsNames.filter((x) => customlyFlexibleColumns.includes(x));
            width = isBelowLg
                ? maxWidth -
                  (breakpointsTheme.breakpoints.values['lg'] - globalThis.window.innerWidth) /
                      columnsIntersection.length
                : maxWidth;
        }
        if (!width) return undefined;
        if (width <= minWidth) return minWidth;
        if (width >= maxWidth) return maxWidth;
        return width;
    };

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails: {
            result = getLevel2Flows({ sortDirection: 'desc', top: 1000 });

            columnDefs = [
                {
                    headerName: 'CFRA Category 2',
                    field: 'level_2_name',
                    minWidth: isBelowLg ? 170 : undefined,
                },
                {
                    headerName: '3 Month Flows',
                    field: 'net_flows_three_month',
                    valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
                    type: 'rightAligned',
                },
                {
                    headerName: 'Total Assets',
                    field: 'total_net_assets',
                    valueFormatter: getAgGridFormatter(ValueTypes.Numeral),
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Flows to Asset',
                    field: 'flow_to_asset_ratio',
                    sort: 'desc',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: 'Number of ETFs',
                    field: 'number_of_etfs',
                    type: 'rightAligned',
                    flex: 1,
                },
            ];
            break;
        }
        case MarketTrend.PerformanceDetails: {
            result = getLevel2Performance({ sortDirection: 'desc', top: 1000 });
            const renderer = getRenderer('cfraId');

            columnDefs = [
                {
                    headerName: 'CFRA Category 2',
                    field: 'level_2_name',
                    minWidth: isBelowLg ? 170 : undefined,
                },
                {
                    headerName: 'ETF Name',
                    field: 'composite_name',
                    cellRenderer: renderer,
                    minWidth: isBelowLg ? 170 : undefined,
                },
                {
                    headerName: 'Ticker',
                    field: 'composite_ticker',
                    cellRenderer: renderer,
                },
                {
                    headerName: '3 Month Return',
                    field: 'return_split_and_dividend_three_month',
                    sort: 'desc',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: 'Number of ETFs',
                    field: 'number_of_etfs',
                    type: 'rightAligned',
                    flex: 1,
                },
            ];
            break;
        }
        case MarketTrend.KeyAssetClassesDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: keyAssetClassesTickerExchanges,
            });
            const renderer = getRenderer('cfraId');

            columnDefs = [
                {
                    headerName: 'Asset Class',
                    field: 'asset_class',
                    sort: 'asc',
                },
                {
                    headerName: 'ETF Name',
                    field: 'composite_name',
                    cellRenderer: renderer,
                    minWidth: isBelowLg ? 170 : undefined,
                },
                { headerName: 'Ticker', field: 'composite_ticker', cellRenderer: renderer },
                {
                    headerName: 'YTD Return',
                    field: 'performance.split_and_dividend_ytd_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Month Return',
                    field: 'performance.month_1_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Return',
                    field: 'performance.month_3_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Return',
                    field: 'performance.return_split_and_dividend_one_year',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                { headerName: 'YTD Flows', field: 'tbd', type: 'rightAligned' },
                {
                    headerName: '1 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
            ];

            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: SectorPerformanceTickerExchanges,
            });
            const renderer = getRenderer('ticker/exchange');

            columnDefs = [
                { headerName: 'Sector', field: 'sector', sort: 'asc' },
                {
                    headerName: 'ETF Name',
                    field: 'composite_name',
                    cellRenderer: renderer,
                    minWidth: isBelowLg ? 170 : undefined,
                },
                { headerName: 'Ticker', field: 'composite_ticker', cellRenderer: renderer },
                {
                    headerName: 'YTD Return',
                    field: 'performance.split_and_dividend_ytd_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Month Return',
                    field: 'performance.month_1_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Return',
                    field: 'performance.month_3_return',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Return',
                    field: 'performance.return_split_and_dividend_one_year',
                    valueFormatter: getAgGridFormatter(ValueTypes.Percentage),
                    type: 'rightAligned',
                },
                { headerName: 'YTD Flows', field: 'tbd', type: 'rightAligned' },
                {
                    headerName: '1 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '3 Month Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
                {
                    headerName: '1 Year Flows',
                    field: 'tbd',
                    type: 'rightAligned',
                },
            ];

            break;
        }
        default: {
            throw new Error(`${label} doesn't exist.`);
        }
    }

    const [title, setTitle] = useOutletContext<ContextType>();

    useLayoutEffect(() => {
        setTitle(label);
    }, [setTitle, label]);

    if (result.isLoading || title !== label) {
        return <ETFCard isLoading={result.isLoading || title !== label} />;
    }

    if (!result.data) return null;

    let rowsData: Array<any>;

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails: {
            rowsData = (result.data as ETFFlowsToAssetThemesAndFactorsData[]).map((row) => ({
                ...row,
                number_of_etfs: 'TBD',
            }));
            break;
        }
        case MarketTrend.PerformanceDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                number_of_etfs: 'TBD',
            }));
            break;
        }
        case MarketTrend.KeyAssetClassesDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                tbd: 'TBD',
                asset_class: keyAssetClassesTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            rowsData = (result.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                tbd: 'TBD',
                sector: SectorPerformanceTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        default: {
            throw new Error(`${label} doesn't exist.`);
        }
    }

    const labelToNumberOfRowsToShow: Partial<Record<MarketTrend, number>> = {
        [MarketTrend.FlowsToAssetsDetails]: 20,
        [MarketTrend.PerformanceDetails]: 20,
    };

    // export should be implemented in DS-4515
    const exportData: any[][] = [];
    const exportColumns = new Map<string, ValueTypes>();
    const dataPointNameToCellStyle: { [id: string]: ColumStyle } = {};

    const excelTable: ExcelTable = {
        data: exportData,
        columns: exportColumns,
        columnStyles: dataPointNameToCellStyle,
    };

    const excelMetadata: ExcelMetadata = {
        cardName: label,
    };

    return (
        <Page name={label} group='market trends details'>
            <ETFCard containerStyles={{ padding: '0px' }}>
                <Grid container sx={{ padding: '28px 28px 0px 28px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ItemHeader sx={{ paddingBottom: '12px' }}>{label}</ItemHeader>
                        <TableExportMenus exportCallback={() => exportExcel(excelTable, excelMetadata)} />
                    </Grid>
                    <Grid item xs={12}>
                        <ChartDescription>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel
                            sint commodi repudiandae consequuntur voluptatum laborum.
                        </ChartDescription>
                    </Grid>
                </Grid>
                <AgGrid
                    columnDefs={columnDefs!.map((obj) => ({
                        minWidth: getMinWidthForHeader(obj.headerName),
                        ...obj,
                    }))}
                    getResizebleMinWidthForColumn={getMinWidthForHeader}
                    getCustomlyFlexColumnWidth={getCustomlyFlexColumnWidth}
                    rowsData={rowsData}
                    numberOfRowsToShow={labelToNumberOfRowsToShow[label]}
                />
            </ETFCard>
        </Page>
    );
}

import { styled } from '@mui/material/styles';
import { fFunction } from './types';
import { fontFamilies } from 'utils';
import Fab from '@mui/material/Fab';
import { tooltipTheme } from 'components/themes/theme';
import { ThemeProvider } from '@mui/material';
import Tooltip from 'components/dataDisplay/Tooltip';

type ButtonProps = {
    callback?: fFunction;
    text: string;
    disabled?: boolean;
    isSelected: boolean;
};

export const CustomFab = styled(Fab)(({ theme }) => ({
    '&:hover': {
        backgroundColor: '#007BB8',
        color: '#ffffff',
    },
    '&:disabled': {
        backgroundColor: '#B1BCC9',
        color: '#ffffff',
    },
    fontSize: '14px',
    fontFamilies: fontFamilies.GraphikRegular,
    height: '36px',
    color: '#007BB8',
    backgroundColor: '#fff',
}));

export function ETFCircleButton(props: ButtonProps) {
    const fab = (
        <CustomFab
            size='small'
            disabled={props.disabled}
            onClick={props.callback}
            name={props.text}
            sx={props.isSelected ? { backgroundColor: '#007BB8', color: '#ffffff' } : {}}>
            {props.text}
        </CustomFab>
    );

    return props.disabled ? (
        <ThemeProvider theme={tooltipTheme}>
            <Tooltip title={'No Data'}>
                <span>{fab}</span>
            </Tooltip>
        </ThemeProvider>
    ) : (
        fab
    );
}

import {
    Autocomplete,
    createTheme,
    ThemeProvider,
    Checkbox,
    TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from 'components/layout/Grid';
import { breakpointsTheme } from 'components/themes/theme';
import React from 'react';
import { fontFamilies } from 'utils/fonts';
import { AggegateResult } from '../types/opensearch';
import { SearchQuery } from '../types/props';
import { getAggregateData } from '../api/search';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

type AggregateFilterProps = {
    keyword: string;
    searchQuery: SearchQuery | undefined;
    text: string;
    selectedOptions: Array<AggegateResult>;
    setSelectedOptions: React.Dispatch<React.SetStateAction<Array<AggegateResult>>>;
};

export function AggregateFilter({
    keyword,
    searchQuery,
    text,
    selectedOptions,
    setSelectedOptions,
}: AggregateFilterProps) {
    // return;
    // getting UseQueryResult object with data for creditExposure chart
    const aggregateQueryResult = getAggregateData({
        keyword: keyword,
        searchQuery: searchQuery,
    });

    const showInput = false
    // const [options, setOptions] = React.useState<AggegateResult[]>([]);
    let options: AggegateResult[] = [];
    let theme = createTheme(breakpointsTheme);
    // const [selectedOptions, setSelectedOptions] = useState<Array<AggegateResult>>([]);

    // show card loading if data still loading
    if (aggregateQueryResult.isLoading) {
        return null;
    } else if (aggregateQueryResult.data) {
        //setOptions([]);
        options =
            aggregateQueryResult.data?.aggregations[2].buckets === undefined
                ? []
                : aggregateQueryResult.data?.aggregations[2].buckets;
        // setOptions(
        //     aggregateQueryResult.data?.aggregations[2].buckets === undefined
        //         ? []
        //         : aggregateQueryResult.data?.aggregations[2].buckets,
        // );
    }

    theme = createTheme(theme, {
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: '#3078B5',
                        width: 20,
                        height: 20,
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                    color:'#707070',
                    fontSize: 16,
                    font: "inherit"
                }
            }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        [theme.breakpoints.down('sm')]: {
                            display: 'flex',
                        },
                    },
                    input: {
                        font: fontFamilies.GraphikLight,
                        fontSize: 14,
                        color: '#57626a',
                        letterSpacing: '0.8px',
                        [theme.breakpoints.down('sm')]: {
                            width: !showInput && '0px',
                            padding: !showInput && '0px',
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '180px',
                        },
                        paddingLeft: '24px',
                    },
                },
                defaultProps: {
                    placeholder: 'Filter by ' + text,
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                        boxShadow: 'none',
                    },
                    root: {
                        [theme.breakpoints.down('sm')]: {
                            flexGrow: '1 !important',
                        },
                    },
                    listbox: {
                        padding: '12px 0px 12px',
                        '::-webkit-scrollbar': {
                            width: '20px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: '#d1d8e8',
                        },
                        '::-webkit-scrollbar-track-piece': {
                            backgroundColor: '#f2f5fc',
                        },
                    },
                    option: {
                        padding: '12px 24px 12px !important',
                        ':hover': {
                            backgroundColor: '#f7f6f7',
                        },
                    },
                },
            },
        },
    });

    const ResultRow = styled(Grid)(({ theme }) => ({
        flexWrap: 'nowrap',
    }));

    const ResultItem = styled(Grid)(({ theme }) => ({
        '&:not(:last-of-type)': {
            paddingRight: '24px',
        },
        font: fontFamilies.GraphikRegular,
        fontSize: 14,
        color: '#57626a',
    }));

    // const handleDelete = (chipToDelete: AggegateResult) => () => {
    //     setSelectedOptions((chips) => chips.filter((chip: AggegateResult) => chip.key !== chipToDelete.key));
    // };

    return (
        <ThemeProvider theme={theme}>
            {/* <Autocomplete
                        multiple
                        id='tags-outlined'
                        options={options}
                        getOptionLabel={(option) => option.key}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField {...params} label='filterSelectedOptions' placeholder='Favorites' />
                        )}
                    /> */}

            <Autocomplete
                multiple
                style={{ width: '100%' }}
                onChange={(event: any, newValue: AggegateResult[] | null) => {
                    setSelectedOptions(newValue === null ? [] : newValue);
                    //console.log(newValue);
                }}
                value={selectedOptions}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                getOptionLabel={(option) => `${option.key}`}
                disableCloseOnSelect
                options={options}
                // renderInput={(params) => (
                //     <InputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus />
                // )}
                renderInput={(params) => <TextField {...params} label={text} placeholder={'Filter by ' + text} />}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            <ResultRow container alignItems='left'>
                                <ResultItem item xs={2}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 3, padding: '0px' }}
                                        checked={selected}
                                    />{' '}
                                </ResultItem>
                                <ResultItem item xs={8}>
                                    {option.key}
                                </ResultItem>
                                <ResultItem item xs={2}>
                                    {option.doc_count}
                                </ResultItem>
                            </ResultRow>
                        </li>
                    );
                }}
            />

            {/* <Grid>
                {selectedOptions.length === 0 ? (
                    <></>
                ) : (
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                        }}
                        component='ul'>
                        {selectedOptions.map((data) => {
                            return (
                                <ListItem key={data.key}>
                                    <Chip label={data.key} onDelete={handleDelete(data)} />
                                </ListItem>
                            );
                        })}
                    </Paper>
                )}
            </Grid> */}
        </ThemeProvider>
    );
}

import { Box, styled } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Page } from 'analytics/Page';
import Feature1Icon from 'assets/images/ETFFeature1Icon.svg';
import Feature2Icon from 'assets/images/ETFFeature2Icon.svg';
import Feature3Icon from 'assets/images/ETFFeature3Icon.svg';
import PlaceholderImg from 'assets/images/Placeholder.svg';
import { ETFPageContainer } from 'components/layout/ETFPageContainer';
import { homePageTheme } from 'components/themes/theme';
import { FeatureList } from '../components/FeatureList';
import { HeroSection } from '../components/HeroSection/HeroSection';
import { LatestResearch } from '../components/LatestResearch';

const FEATURES_AND_BENEFITS = [
    { img: Feature1Icon, text: 'Identify new product or investment opportunities based on current flow trends.' },
    {
        img: Feature2Icon,
        text: 'Get insight into the true exposure of your product or investment as well as comparable ETFs.',
    },
    { img: Feature3Icon, text: 'Understand and break down ETF asset size based on market movement and new flows.' },
];

const LATEST_RESEARCH_AND_RESOURCES = {
    latestResearch: {
        image: PlaceholderImg,
        title: 'U.S. ETF Market Monthly Update',
        description:
            'The U.S. ETF market has shrunk to $6.76T as of February 28, 2022. This is $192B lower than in January and still $300B less than its peak over the past 12 months - March 2022….',
        pdfUrl: 'pdfs/US_ETF_Market_March_2023.pdf',
    },
    relatedResources: [
        {
            title: 'CFRA Data Definitions',
            description: 'CFRAs classification framework and category definitions.',
            pdfUrl: 'pdfs/CFRA_Classifications_Definitions.pdf',
        },
    ],
};

export function Home() {
    const HeroSectionContainer = styled(Box)(({ theme }) => ({
        padding: '90px 24px',
        backgroundColor: '#F2F5FC',
        maxWidth: '1366px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: '90px 60px',
        },
    }));

    const FeatureListContainer = styled(Box)(({ theme }) => ({
        padding: '28px 24px 84px 24px',
        backgroundColor: '#F2F5FC',
        maxWidth: '1366px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: '28px 60px 84px 60px',
        },
    }));

    const LatestResearchContainer = styled(Box)(({ theme }) => ({
        padding: '72px 24px 72px 24px',
        maxWidth: '1366px',
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            padding: '72px 60px 116px 60px',
        },
    }));

    return (
        <Page name='home'>
            <ThemeProvider theme={homePageTheme}>
                <ETFPageContainer containerStyles={{ maxWidth: 'unset', backgroundColor: 'unset' }}>
                    <HeroSectionContainer component='section'>
                        <HeroSection />
                    </HeroSectionContainer>

                    <FeatureListContainer component='section'>
                        <FeatureList title='Platform Features & Benefits' features={FEATURES_AND_BENEFITS} />
                    </FeatureListContainer>

                    <Box component='section' bgcolor='#fff'>
                        <LatestResearchContainer>
                            <LatestResearch
                                latestResearch={LATEST_RESEARCH_AND_RESOURCES.latestResearch}
                                relatedResources={LATEST_RESEARCH_AND_RESOURCES.relatedResources}
                            />
                        </LatestResearchContainer>
                    </Box>
                </ETFPageContainer>
            </ThemeProvider>
        </Page>
    );
}

import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import { getLevel2Flows } from 'features/marketTrends/api/market';
import { HomeProps, MarketTrend } from 'features/marketTrends/types/market';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { ValueTypes, PerformanceDateRanges } from 'utils';
import { getColumnHeaderFormatter, ThemesAndFactorsChart, xAxisColumnName } from './shared/ThemesAndFactors';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import {
    themeCategories,
    flowOrderFields,
    getChangeEvents,
    dataLimitForAssetCategory,
    mappingFunction,
    categoriesMapFunction,
} from './shared/ThemeFilters';

import { ETFFlowsToAssetThemesAndFactorsData } from 'features/marketTrends/types/research';

const localStorageID = 'market-trends-etf-flows-themes-and-factors-';

export default function ETFFlowsToAssetThemesAndFactorsChart({ details = true }: HomeProps) {
    const [highestChartRef, setHighestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [lowestChartRef, setLowestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();

    const [currentActiveTab, setCurrentActiveTab] = useLocalStorage(
        localStorageID + 'active-tab',
        2
    );

    const [currentPeriod, setCurrentPeriod] = useLocalStorage(localStorageID + 'active-perod', '3M');
    const { handleTabChange, handlePeriodChange } = getChangeEvents(setCurrentActiveTab, setCurrentPeriod);

    const mapFunction = mappingFunction(flowOrderFields);

    const dataMapFunction = function (item: string) {
        return getLevel2Flows({
            sortDirection: item as 'desc' | 'asc',
            orderBy: flowOrderFields.get(currentPeriod),
            aggregateBy: themeCategories[currentActiveTab].aggregateField,
            top: currentActiveTab === 0 ? dataLimitForAssetCategory : undefined,
        });
    };

    let chartData = ['desc', 'asc'].map(dataMapFunction);
    if (currentActiveTab === 0) chartData = [chartData[0]];

    // show card loading if data still loading
    if (chartData.find((data) => data.isLoading)) {
        return <ETFCard isLoading={true} />;
    }

    const cardTitle = 'Themes & Factors: Flows to Assets';
    // Show Nothing if no data
    if (!chartData.every((item) => item.data && item.data.length > 0)) {
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;
    }

    const yAxisColumnName = 'Flows to Assets Ratio';

    const columnsToFormatting = new Map<string, ValueTypes>();
    columnsToFormatting.set(xAxisColumnName, ValueTypes.Text);
    columnsToFormatting.set(yAxisColumnName, ValueTypes.Numeral);

    const asOfDate = chartData
        .flatMap((item) => item.data)
        .map((item) => item?.as_of_date)
        .sort()
        .reverse()[0] || '';

    const getChartData = () => {
        return chartData.map((item, index) => {
            return {
                chartHeader: currentActiveTab === 0 ? 'Assets' : (index === 0 ? 'Highest' : 'Lowest') + ' Performing',
                categories:
                    item.data?.map((value) =>
                        categoriesMapFunction<ETFFlowsToAssetThemesAndFactorsData>({ value, currentActiveTab }),
                    ) || [],
                categoriesData:
                    item.data?.map((value) =>
                        mapFunction<ETFFlowsToAssetThemesAndFactorsData>({ value, currentPeriod }),
                    ) || [],
                asOfDates: item.data?.map((value) => value.as_of_date) || [],
                chartRefObject: index === 0 ? highestChartRef : lowestChartRef,
                chartRefSetter: index === 0 ? setHighestChartRef : setLowestChartRef,
                columnsToFormatting: columnsToFormatting,
            };
        });
    };

    return (
        <ETFCard containerStyles={{ paddingBottom: '16px', position: 'relative' }}>
            <ThemesAndFactorsChart
                title={cardTitle}
                subHeader={'View the highest and lowest ETF categories by Flows to Assets ratio'}
                tooltipFormatterValuePrefix={`${yAxisColumnName}:`}
                columnHeaderFormatter={getColumnHeaderFormatter(yAxisColumnName)}
                exportFileName={`performance-themes-and-factors-${currentPeriod}`}
                detailsPathName={details ? MarketTrend.FlowsToAssetsDetails : undefined}
                subChartsProps={getChartData()}
                chartFilters={{
                    data: {
                        periods: Object.values(PerformanceDateRanges),
                        categories: [],
                    },
                    eventHandlers: {
                        tabChange: handleTabChange,
                        periodChange: handlePeriodChange,
                    },
                    selections: {
                        period: currentPeriod,
                        tab: currentActiveTab,
                    },
                }}
            />
            <DataAsOfDate date={asOfDate} />
        </ETFCard>
    );
}

import { KeywordSearch } from './KeywordSearch';
import { TickerSearch } from './TickerSearch';
import Grid from 'components/layout/Grid';
import Button from 'components/form/Button';
import { ReactComponent as SearchIcon } from 'assets/images/SearchIcon.svg';
import { AggregateFilter } from './AggregateFilter';
import { KeywordFilter, SearchPanelProps, SearchQuery } from '../types/props';
import { useEffect, useState } from 'react';
import { AggegateResult, AutoSuggestTicker } from '../types/opensearch';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, createTheme, FormControl, ThemeProvider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { fontFamilies } from 'utils/fonts';

export function SearchPanel({ onSearchExecute, onRefineSearch, searchQuery }: SearchPanelProps) {
    const [inputValue, setInputValue] = useState('');
    const [selectedTickers, setSelectedTickers] = useState<Array<AutoSuggestTicker>>([]);
    const [selectedTopics, setSelectedTopics] = useState<Array<AggegateResult>>([]);
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<Array<AggegateResult>>([]);
    const [selectedAuthors, setSelectedAuthors] = useState<Array<AggegateResult>>([]);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [sortValue, setSortValue] = useState<string | undefined>('default');

    const now = new Date();

    const today = now.getDate();
    
    const tomorrow = new Date(now);

    const minDate = new Date('01/01/2004');
    
    tomorrow.setDate(today + 1);

    const mapOptionsToFilter = (
        keyword_filters: Array<KeywordFilter>,
        name: string,
        selectedOptions: Array<AggegateResult>,
    ) => {
        if (selectedOptions && selectedOptions.length > 0) {
            keyword_filters.push({
                name: name,
                values: selectedOptions.map((option) => option.key),
            });
        }
    };

    const ResultItem = styled(MenuItem)(() => ({
        font: fontFamilies.GraphikRegular,
        fontSize: 14,
        color: '#57626a',
    }));

    const getFilterDates = () => {
        if (fromDate || toDate) {
            return [
                {
                    name: 'publish_date',
                    to: toDate,
                    from: fromDate,
                },
            ];
        }
        return [];
    };

    const theme = createTheme({
        components: {
            MuiIconButton: {
                styleOverrides: {
                    sizeMedium: {
                        font: fontFamilies.GraphikRegular,
                        fontSize: 14,
                        color: '#57626a',
                        letterSpacing: '0.8px',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        font: fontFamilies.GraphikRegular,
                        fontSize: 14,
                        color: '#57626a',
                        letterSpacing: '0.8px',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        font: fontFamilies.GraphikRegular,
                        fontSize: 14,
                        color: '#57626a',
                        letterSpacing: '0.8px',
                    },
                },
            },
        },
    });

    const handleSortChange = (event: SelectChangeEvent) => {
        setSortValue(event.target.value);
        onRefineSearch(event.target.value);
    };

    const handleSearchButtonClick = () => {
        executeSearch();
    };

    const filterSearchQueryByAggregate = (searchQuery: SearchQuery | undefined, keyword: string) => {
        return searchQuery
            ? {
                  queryString: searchQuery.queryString,
                  keyword_filters: searchQuery.keyword_filters.filter((x) => x.name !== keyword),
                  date_filters: searchQuery.date_filters,
              }
            : undefined;
    };

    const executeSearch = () => {
        const keyword_filters: Array<KeywordFilter> = [];

        if (selectedTickers && selectedTickers.length > 0) {
            keyword_filters.push({
                name: 'verified_tickers.tradingitem_id',
                values: selectedTickers.map((ticker) => ticker.tradingitem_id),
            });
        }

        mapOptionsToFilter(keyword_filters, 'topics.keyword', selectedTopics);
        mapOptionsToFilter(keyword_filters, 'document_type.keyword', selectedDocumentTypes);
        mapOptionsToFilter(keyword_filters, 'authors.pen_name.keyword', selectedAuthors);

        const newSearchQuery: SearchQuery = {
            queryString: inputValue,
            keyword_filters: keyword_filters,
            date_filters: getFilterDates(),
        };

        onSearchExecute(newSearchQuery);
    };

    useEffect(() => {
        executeSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTopics, selectedAuthors, selectedDocumentTypes, selectedTickers, fromDate, toDate]);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <KeywordSearch inputValue={inputValue} setInputValue={setInputValue} />
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant='contained'
                            endIcon={<SearchIcon />}
                            sx={{ backgroundColor: '#3078b5' }}
                            onClick={handleSearchButtonClick}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: '5px' }} spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <TickerSearch selectedTickers={selectedTickers} setSelectedTickers={setSelectedTickers} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} >
                      
                            <DatePicker
                                sx={{  width: '100%' }}
                                label='Publish Date From'
                                value={fromDate}
                                minDate={minDate}
                                maxDate={tomorrow}
                                onChange={(newValue: any) => {
                                    if(newValue === null || (newValue instanceof Date && newValue > minDate && newValue < tomorrow ))
                                    {
                                        setFromDate(newValue);
                                    }
                                }}
                            />
                            </Grid>
                            <Grid item xs={12}  sm={6}>
                            <DatePicker
                                sx={{ width: '100%' }}
                                label='Publish Date To'
                                value={toDate}
                                minDate={minDate}
                                maxDate={tomorrow}
                                onChange={(newValue: any) => {
                                    if(newValue === null || (newValue instanceof Date && newValue > minDate && newValue < tomorrow )) {
                                        setToDate(newValue);
                                    }
                                }}
                            />
                            </Grid>
                                 </Grid>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}></LocalizationProvider>
                    </Grid>
                </Grid>

                <Grid sx={{ marginTop: '5px' }} container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <AggregateFilter
                            keyword={'document_type.keyword'}
                            searchQuery={filterSearchQueryByAggregate(searchQuery, 'document_type.keyword')}
                            selectedOptions={selectedDocumentTypes}
                            setSelectedOptions={setSelectedDocumentTypes}
                            text='Document Type'
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AggregateFilter
                            keyword={'topics.keyword'}
                            searchQuery={filterSearchQueryByAggregate(searchQuery, 'topics.keyword')}
                            selectedOptions={selectedTopics}
                            setSelectedOptions={setSelectedTopics}
                            text='Topic'
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AggregateFilter
                            keyword={'authors.pen_name.keyword'}
                            searchQuery={filterSearchQueryByAggregate(searchQuery, 'authors.pen_name.keyword')}
                            selectedOptions={selectedAuthors}
                            setSelectedOptions={setSelectedAuthors}
                            text='Author'
                        />
                    </Grid>
                </Grid>
                <Grid sx={{ marginTop: '5px' }} container spacing={2}>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='flex-end'>
                            <FormControl size='small'>
                                <InputLabel
                                    sx={{
                                        m: 1,
                                        minWidth: 120,
                                        font: fontFamilies.GraphikRegular,
                                        fontSize: 14,
                                        color: '#57626a',
                                        letterSpacing: '0.8px',
                                    }}
                                    id='demo-select-small'>
                                    Sort by
                                </InputLabel>
                                <Select
                                    labelId='demo-select-small'
                                    id='demo-select-small'
                                    value={sortValue}
                                    sx={{
                                        m: 1,
                                        minWidth: 120,
                                        font: fontFamilies.GraphikRegular,
                                        fontSize: 14,
                                        color: '#57626a',
                                        letterSpacing: '0.8px',
                                    }}
                                    label='Sort By'
                                    onChange={handleSortChange}>
                                    <ResultItem value={'default'}>Default</ResultItem>
                                    <ResultItem value={'_score|desc'}>Best Match</ResultItem>
                                    <ResultItem value={'publish_date|desc'}>Publish Date - Newest</ResultItem>
                                    <ResultItem value={'publish_date|asc'}>Publish Date - Oldest</ResultItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    );
}

import { ChangeEvent } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { breakpointsTheme } from 'components/themes/theme';
import { fontFamilies } from 'utils/fonts';
import TextField from '@mui/material/TextField';

type KeywordSearchProps = {
    inputValue: string;
    setInputValue: any;
};

export function KeywordSearch({ inputValue, setInputValue }: KeywordSearchProps) {
    let theme = createTheme(breakpointsTheme);

    theme = createTheme(theme, {
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: '#3078B5',
                        width: 24,
                        height: 24,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        height: 54,
                        [theme.breakpoints.down('sm')]: {
                            display: 'flex',
                        },
                    },
                    input: {
                        font: fontFamilies.GraphikRegular,
                        fontSize: 14,
                        color: '#57626a',
                        letterSpacing: '0.8px',
                        width: '100%',
                        // [theme.breakpoints.down('sm')]: {
                        //     width: !showInput && '0px',
                        //     padding: !showInput && '0px',
                        // },
                        // [theme.breakpoints.up('sm')]: {
                        //     width: '280px',
                        // },
                        paddingLeft: '24px',
                    },
                },
                defaultProps: {
                    placeholder: 'Search by Keyword or Phrase',
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                        boxShadow: 'none',
                    },
                    root: {
                        [theme.breakpoints.down('sm')]: {
                            flexGrow: '1 !important',
                        },
                    },
                    listbox: {
                        padding: '12px 0px 12px',
                        '::-webkit-scrollbar': {
                            width: '20px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: '#d1d8e8',
                        },
                        '::-webkit-scrollbar-track-piece': {
                            backgroundColor: '#f2f5fc',
                        },
                    },
                    option: {
                        padding: '12px 24px 12px !important',
                        ':hover': {
                            backgroundColor: '#f7f6f7',
                        },
                    },
                },
            },
        },
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <ThemeProvider theme={theme}>
            {/* <Grid container sx={SearchBarMainContainerStyles}>
                {/* <Grid item sx={SearchBarItemStyles}> */}
            {/* <Grid item xs={12}>  */}
            <TextField
                id='outlined-search'
                type='search'
                value={inputValue}
                onChange={handleChange}
                sx={{ width: '100%' }}
            />
            {/* </Grid> */}
            {/* <Grid item sx={SearchBarItemStyles}>
                    <SvgIcon viewBox='0 0 40 40'>
                        <SearchIcon />
                    </SvgIcon>
                </Grid> */}
            {/* </Grid> */}
        </ThemeProvider>
    );
}

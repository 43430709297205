import OverviewCircle from 'assets/images/OverviewCircle.svg';
import { ETFDivider } from 'components/layout/ETFDivider';
import { ETFCard } from 'components/layout/ETFCard';
import { ETFPageContainer } from 'components/layout/ETFPageContainer';
import { ETFTabContainer } from 'components/layout/ETFTabContainer';
import { SearchPanel } from '../components/SearchPanel';
import Grid from 'components/layout/Grid';
import { useState } from 'react';
import { SearchQuery } from '../types/props';
import { SearchResults } from './SearchResults';

export function DocumentSearch() {
    const [searchQuery, setSearchQuery] = useState<SearchQuery | undefined>(undefined);
    const [sort, setSort] = useState<string>('default');
    const [from, setFrom] = useState<number>(0);
    const [size, setSize] = useState<number>(10);

    const handleExecuteSearch = (query: SearchQuery) => {
        setSearchQuery(query);
    };

    const handleRefineSearch = (sort: string, from: number, size: number) => {
        setSort(sort);
        setFrom(from);
        setSize(size);
    };

    return (
        <ETFPageContainer>
            <ETFTabContainer
                style={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'center',
                }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ETFDivider
                            imageSource={OverviewCircle}
                            header='Policy Insights'
                            description='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <ETFCard containerStyles={{ paddingTop: '18px', paddingBottom: '18px' }}>
                            <SearchPanel
                                onSearchExecute={handleExecuteSearch}
                                onRefineSearch={handleRefineSearch}
                                searchQuery={searchQuery}
                            />
                        </ETFCard>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <ETFCard containerStyles={{ paddingTop: '18px', paddingBottom: '18px' }}>
                            <SearchResults searchQuery={searchQuery} sort={sort} from={from} size={size} />
                        </ETFCard>
                    </Grid>
                </Grid>
            </ETFTabContainer>
        </ETFPageContainer>
    );
}

import { ETFSearchByParams, getRequestQuery, UseData } from 'utils';
import {
    ETFFlowsToAssetThemesAndFactorsData,
    ETFPerformanceThemesAndFactorsData,
    KeyAssetClassesData,
} from '../types/research';

export function getKeyAssetClassesData({ cfraIds, tickerExchanges }: ETFSearchByParams) {
    return UseData<Array<KeyAssetClassesData>>({
        requestQuerry: getRequestQuery({ cfraIds, tickerExchanges }, 'performance/'),
        queryKey: ['getKeyAssetClassesData', `${JSON.stringify(cfraIds)}`, `${JSON.stringify(tickerExchanges)}`],
    });
}

export function getLevel2Flows({
    top = 5,
    orderBy = 'flows_to_assets_ratio_three_month',
    sortDirection,
    aggregateBy,
}: ETFSearchByParams) {
    return UseData<Array<ETFFlowsToAssetThemesAndFactorsData>>({
        requestQuerry: getRequestQuery({ top, orderBy, sortDirection, aggregateBy }, 'aggregation/flows/'),
        queryKey: ['getLevel2Flows', top, orderBy, sortDirection, aggregateBy],
    });
}

export function getLevel2Performance({ top = 5, sortDirection, orderBy, aggregateBy }: ETFSearchByParams) {
    return UseData<Array<ETFPerformanceThemesAndFactorsData>>({
        requestQuerry: getRequestQuery({ top, sortDirection, orderBy, aggregateBy }, 'aggregation/performance/'),
        queryKey: ['getLevel2Performance', top, sortDirection, orderBy, aggregateBy],
    });
}

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { BarChartWithBigLabels } from 'components/Chart/BarWithBigLabels';
import { getDefaultFontStyle, getTooltipHTML } from 'components/Chart/Options';
import { NumberOrNull } from 'components/Chart/types';
import { HorizontalPanel, ItemHeader } from 'components/layout';
import Grid from 'components/layout/Grid';
import { MarketTrendsChartsTopPanel } from 'features/marketTrends/components/charts/shared/MarketTrendsChartsTopPanel';
import { MarketTrend } from 'features/marketTrends/types/market';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { ReactNode } from 'react';
import { fontFamilies, getMomentObjectFrom, ValueTypes } from 'utils';
import { ChartTabs } from 'components/Chart/ChartTabs';
import CFRASelect from 'components/CFRASelect';
import Tooltip from 'components/dataDisplay/Tooltip';
import { tooltipThemeV2 } from 'components/themes/theme';
import { ThemeProvider } from '@mui/material';

type SubChartProps = {
    chartHeader: string;
    categories: Array<string>;
    categoriesData: Array<NumberOrNull>;
    asOfDates: Array<string>;
    tickers?: Array<string>;
    reverseYaxis?: boolean;
    chartRefObject?: React.RefObject<HighchartsReact.RefObject>;
    chartRefSetter: (value: any) => void;
    columnsToFormatting: Map<string, ValueTypes>;
};

type ThemesAndFactorsChartProps = {
    title: string;
    subHeader: string | ReactNode;
    exportFileName: string;
    detailsPathName?: MarketTrend;
    subChartsProps: Array<SubChartProps>;
    tooltipFormatterValuePrefix: string;
    columnHeaderFormatter: (item: any, key: any) => string;
    chartFilters?: {
        data: {
            periods: Array<string>;
            categories: Array<{ key: string; value: string; isDefault?: boolean }>;
        };
        eventHandlers: {
            tabChange: (event: React.SyntheticEvent, newValue: number) => void;
            periodChange: (event: any) => void;
        };
        selections: {
            period: string;
            tab: number;
        };
    };
};

const PaleItemSubHeader = styled(ItemHeader)(({ theme }) => ({
    ...getDefaultFontStyle(15),
}));

const ChartHeader = styled(Paper)(({ theme }) => ({
    fontFamily: fontFamilies.GraphikMedium,
    lineHeight: 1,
    fontSize: '15px',
    color: '#57626a',
    borderBottom: '1px solid #74828D',
    borderRadius: '0px',
    paddingBottom: '0px',
}));

export const xAxisColumnName = 'Company name';

export function getColumnHeaderFormatter(yAxisColumnName: string) {
    return function (item: any, key: any): string {
        if (item instanceof Highcharts.Axis && item.isXAxis) return xAxisColumnName;
        return yAxisColumnName;
    };
}

export function ThemesAndFactorsChart({
    title,
    subHeader,
    subChartsProps,
    exportFileName,
    tooltipFormatterValuePrefix,
    columnHeaderFormatter,
    detailsPathName,
    chartFilters,
}: ThemesAndFactorsChartProps) {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const handleTooltip = (state: boolean) => setTooltipOpen(state);

    const chartWidth = subChartsProps.length > 1 ? 6 : 12;

    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <MarketTrendsChartsTopPanel
                title={title}
                detailsPathName={detailsPathName}
                multipleExportProps={{
                    subChartsProps: subChartsProps.map((element) => {
                        return {
                            chartRefObject: element.chartRefObject,
                            chartHeader: element.chartHeader,
                            columnsToFormatting: element.columnsToFormatting,
                        };
                    }),
                    exportFileName: exportFileName,
                }}
            />
            <HorizontalPanel>
                <PaleItemSubHeader>{subHeader}</PaleItemSubHeader>
            </HorizontalPanel>
            {chartFilters ? (
                <HorizontalPanel container sx={{ paddingBottom: '24px' }}>
                    <Grid item xs={chartFilters.data.periods && chartFilters.data.periods.length > 0 ? 10 : 12}>
                        {chartFilters.data.categories.length > 0 ? (
                            <ChartTabs
                                tabs={chartFilters.data.categories}
                                currentActiveTab={chartFilters.selections.tab}
                                handleTabChange={chartFilters.eventHandlers.tabChange}></ChartTabs>
                        ) : (
                            ''
                        )}
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: 'right' }}>
                        <ThemeProvider theme={tooltipThemeV2}>
                            <Tooltip title='Select Period' open={tooltipOpen}>
                                <span>
                                    <CFRASelect
                                        mouseHandler={handleTooltip}
                                        selectItems={chartFilters.data.periods}
                                        currentSelection={chartFilters.selections.period}
                                        handleSelectionChange={chartFilters.eventHandlers.periodChange}
                                    />
                                </span>
                            </Tooltip>
                        </ThemeProvider>
                    </Grid>
                </HorizontalPanel>
            ) : (
                ''
            )}
            <Grid container columnSpacing={3.5} sx={{ maxWidth: '1100px' }}>
                {React.Children.toArray(
                    subChartsProps.map((value) => (
                        <>
                            <Grid item xs={chartWidth}>
                                <ChartHeader sx={{ paddingBottom: '12px' }}>{value.chartHeader}</ChartHeader>
                            </Grid>
                        </>
                    )),
                )}
                {React.Children.toArray(
                    subChartsProps.map((value) => {
                        function tooltipFormatter(
                            this: Highcharts.TooltipFormatterContextObject & { category?: string },
                        ) {
                            // category missed in TooltipFormatterContextObject
                            const asOfDate = value.asOfDates[value.categories.indexOf(this.category as string)];
                            return getTooltipHTML(this.category, [
                                value.tickers
                                    ? `Ticker: ${value.tickers[value.categories.indexOf(this.category as string)]}`
                                    : '',
                                `${tooltipFormatterValuePrefix} ${this.y as number}%`,
                                `As of date: ${getMomentObjectFrom(asOfDate).format('MM/DD/YYYY')}`,
                            ]);
                        }

                        return (
                            <Grid item xs={chartWidth} sx={{ paddingTop: '18px' }}>
                                <BarChartWithBigLabels
                                    activeTab={chartFilters?.selections.tab}
                                    categories={value.categories}
                                    categoriesData={value.categoriesData}
                                    columnHeaderFormatter={columnHeaderFormatter}
                                    setChartRef={value.chartRefSetter}
                                    reverseYaxis={value.reverseYaxis}
                                    tooltipFormatter={tooltipFormatter}
                                />
                            </Grid>
                        );
                    }),
                )}
            </Grid>
        </Box>
    );
}

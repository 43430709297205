import { API_URL } from 'config';
import moment from 'moment';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import { DateRanges, Frequencies } from 'utils';
import { ExtractFnReturnType, QueryConfig } from '../lib/react-query-client';
import { axiosAuth } from './api-common';

const axios = axiosAuth(API_URL);

async function getData<T>(requestQuerry: string): Promise<T> {
    try {
        const result = await axios.get(requestQuerry);
        return result.data;
    } catch (error) {
        console.error(requestQuerry, error);
        return new Promise<T>((resolve, reject) => {
            resolve(undefined as T);
        });
    }
}

type QueryFnType = typeof getData;

export type ETFSearchByParams = {
    cfraId?: string;
    id?: string;
    ticker?: string;
    exchange?: string;
    top?: number;
    numberOfYears?: number;
    cfraIds?: Array<string>;
    tickerExchanges?: Array<{ ticker: string; exchange: string }>;
    dateRange?: DateRanges;
    startDate?: string;
    endDate?: string;
    frequency?: string;
    orderBy?: string;
    sortDirection?: 'asc' | 'desc';
    config?: QueryConfig<QueryFnType>;
    aggregateBy?: string;
};

export type UseDataOptions = {
    requestQuerry: string;
    queryKey: QueryKey;
    config?: QueryConfig<QueryFnType>;
};

export function getFrequency(dateRange: DateRanges): Frequencies {
    if ([DateRanges.OneWeek, DateRanges.OneMonth].includes(dateRange)) return Frequencies.Daily;
    if ([DateRanges.ThreeMonth, DateRanges.SixMonth, DateRanges.OneYear].includes(dateRange)) return Frequencies.Weekly;
    return Frequencies.Monthly;
}

function getDateRangeParameters(dateRange: DateRanges): string {
    const getDateString = (date: Date, dateFormat: string = 'YYYY-MM-DD') => moment.utc(date).format(dateFormat); // format date from Date type to string with format YYYY-MM-DD
    const todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() - 1); // change to yesterdays date
    const endDate = getDateString(todaysDate);
    const frequency = getFrequency(dateRange);

    if ([DateRanges.OneWeek, DateRanges.OneMonth].includes(dateRange)) {
        todaysDate.setMonth(todaysDate.getMonth() - 1); // get 1 month before yesterday date
        const startDate = getDateString(todaysDate);
        const top = 5;
        return dateRange === DateRanges.OneWeek
            ? getKeyValuePairs({ endDate, top, frequency })
            : getKeyValuePairs({ startDate, endDate, frequency });
    }
    if ([DateRanges.ThreeMonth, DateRanges.SixMonth].includes(dateRange)) {
        let numberOfMonthBefore = dateRange === DateRanges.ThreeMonth ? 3 : 6;
        todaysDate.setMonth(todaysDate.getMonth() - numberOfMonthBefore); // get 3 or 6 month before yesterday date
        const startDate = getDateString(todaysDate);
        return getKeyValuePairs({ startDate, endDate, frequency });
    }
    if ([DateRanges.OneYear, DateRanges.ThreeYears, DateRanges.FiveYears].includes(dateRange)) {
        let numberOfYearsBefore = dateRange === DateRanges.OneYear ? 1 : dateRange === DateRanges.ThreeYears ? 3 : 5;
        todaysDate.setFullYear(todaysDate.getFullYear() - numberOfYearsBefore); // get 1 or 3 or 5 year before yesterday date
        const startDate = getDateString(todaysDate);
        return getKeyValuePairs({ startDate, endDate, frequency });
    }
    throw new Error('Invalid dateRange specified');
}

function getKeyValuePairs(params: ETFSearchByParams): string {
    const {
        top,
        numberOfYears,
        startDate,
        endDate,
        frequency,
        cfraIds,
        tickerExchanges,
        dateRange,
        orderBy,
        sortDirection,
        aggregateBy
    } = params;
    let keyValuePairsQuerry: string = '';
    const setKeyValuePair = (keyValuePair: string): void => {
        keyValuePairsQuerry = Boolean(keyValuePairsQuerry) ? `${keyValuePairsQuerry}&${keyValuePair}` : keyValuePair;
    };

    if (top) setKeyValuePair(`top=${top}`);
    if (numberOfYears) setKeyValuePair(`number_of_years=${numberOfYears}`);
    if (dateRange) setKeyValuePair(getDateRangeParameters(dateRange));
    if (startDate) setKeyValuePair(`start_date=${startDate}`);
    if (endDate) setKeyValuePair(`end_date=${endDate}`);
    if (frequency) setKeyValuePair(`frequency=${frequency}`);
    if (cfraIds) setKeyValuePair(`cfra_ids=${cfraIds.join(',')}`);
    if (tickerExchanges)
        setKeyValuePair(
            `ticker-exchanges=${tickerExchanges.map((element) => `${element.ticker}-${element.exchange}`).join(',')}`,
        );
    if (orderBy) setKeyValuePair(`order_by=${orderBy}`);
    if (sortDirection) setKeyValuePair(`sort_direction=${sortDirection}`);
    if (aggregateBy) setKeyValuePair(`aggregate_by=${aggregateBy}`);

    return keyValuePairsQuerry;
}

export function getRequestQuery(params: ETFSearchByParams, path?: string): string {
    const { cfraId, ticker, exchange, cfraIds, tickerExchanges } = params;

    const keyValuePairsQuerry = getKeyValuePairs(params);

    let requestQuerry = path ? path : '';
    if (cfraId) requestQuerry += `cfra_id/${cfraId}`;
    else if (ticker && exchange) requestQuerry += `ticker/${ticker}/exchange/${exchange}`;
    else if (cfraIds) requestQuerry += `cfra_ids`;
    else if (tickerExchanges) requestQuerry += `ticker-exchanges`;
    else if (keyValuePairsQuerry === '') throw new Error('Parameters were not specified');

    return Boolean(keyValuePairsQuerry) ? `${requestQuerry}?${keyValuePairsQuerry}` : requestQuerry;
}

export function UseData<T>({ requestQuerry, queryKey, config }: UseDataOptions): UseQueryResult<T> {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: queryKey,
        queryFn: () => getData<T>(`/etf-insights/etf-data/${requestQuerry}`),
        notifyOnChangeProps: 'tracked',
        ...config,
    }) as UseQueryResult<T>;
}

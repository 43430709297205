import { POLICY_SEARCH_URL } from 'config';
import { ExtractFnReturnType } from 'lib/react-query-client';
import { UseQueryResult, useQuery } from 'react-query';
import { UseDataOptions } from 'utils';
import { axiosAuth } from 'utils/api-common';
import { AggegateResult, AggregateSearchResults, DocumentSearchResult, DocumentResult } from '../types/opensearch';
import { AggregateAPIRequestParams, DocumentAPIRequestParams, SearchQuery } from '../types/props';

type QueryFnType = typeof getData;

const axios = axiosAuth(POLICY_SEARCH_URL);

async function getData<T>(requestQuerry: string): Promise<T> {
    try {
        const result = await axios.get(requestQuerry);
        return result.data;
    } catch (error) {
        console.error(requestQuerry, error);
        return new Promise<T>((resolve, reject) => {
            resolve(undefined as T);
        });
    }
}

export function getAggregateData({ keyword, searchQuery }: AggregateAPIRequestParams) {
    return UseData<AggregateSearchResults<AggegateResult>>({
        requestQuerry: getRequestQuery(keyword, searchQuery, null, null, null, '/search/v1/research/agg'),
        queryKey: ['getAggregateSearchData', keyword, searchQuery],
    });
}

export function getDocumentData({ from, size, searchQuery, sort }: DocumentAPIRequestParams) {
    return UseData<DocumentSearchResult<DocumentResult>>({
        requestQuerry: getRequestQuery(null, searchQuery, from, size, sort, '/search/v1/research/search'),
        queryKey: ['getDocumentSearchData', sort, from, size, searchQuery],
    });
}

export function getRequestQuery(
    keyword: string | null,
    searchQuery: SearchQuery | undefined,
    from: number | null,
    size: number | null,
    sort: string | null,
    path?: string,
): string {
    const searchParams = new URLSearchParams();

    // From
    if (from !== undefined && from !== null) {
        searchParams.set('from', from.toString());
    }

    // Size
    if (size !== undefined && size !== null) {
        searchParams.set('size', size.toString());
    }
    // Sort
    if (sort && sort !== '' && sort !== 'default') {
        searchParams.set('sort', sort);
    }

    // Aggregate Field
    if (keyword) {
        searchParams.set('field', keyword);
    }

    // Querystring
    if (searchQuery?.queryString) {
        searchParams.set('q', searchQuery.queryString);
    }

    // Map Text Field Filters
    if (searchQuery?.keyword_filters && searchQuery.keyword_filters.length > 0) {
        const keyword_filter_strings = searchQuery.keyword_filters.map((keyword_filter) => {
            return `${keyword_filter.name}:${keyword_filter.values.join(',')}`;
        });
        searchParams.set('filter_keywords', keyword_filter_strings.join(';'));
    }

    // Map Date Filters
    if (searchQuery?.date_filters && searchQuery.date_filters.length > 0) {
        const date_filter_strings = searchQuery.date_filters.map((date_filter) => {
            return `${date_filter.name}:${getDateFilterValue(date_filter.from)}|${getDateFilterValue(date_filter.to)}`;
        });
        searchParams.set('filter_dates', date_filter_strings.join(';'));
    }

    const requestQuery = searchParams.toString();

    return `${path}?${requestQuery}`;
}

function getDateFilterValue(date: Date | null) {
    if (date === null) return '';

    const day = date.getDate();
    const month = date.getMonth() + 1;

    return `${date.getFullYear()}-${(month > 9 ? '' : '0') + month}-${(day > 9 ? '' : '0') + day}`;
}

export function UseData<T>({ requestQuerry, queryKey, config }: UseDataOptions): UseQueryResult<T> {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: queryKey,
        queryFn: () => getData<T>(`${requestQuerry}`),
        ...config,
    }) as UseQueryResult<T>;
}

import Grid from 'components/layout/Grid';
import Button from 'components/form/Button';
import Skeleton from 'components/layout/Skeleton';
import { getDocumentData, getRequestQuery } from '../api/search';
import { KeywordFilter, SearchResultsProps } from '../types/props';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

import {
    CardContent,
    CardHeader,
    Card,
    Avatar,
    IconButton,
    Typography,
    Paper,
    Chip,
    Box,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { useState, useEffect } from 'react';
import { DocumentResult } from '../types/opensearch';
import './SearchResults.scss';
import DocumentDialog from './DocumentDialog';
import { axiosAuth } from 'utils/api-common';
import { POLICY_SEARCH_URL } from 'config';

const axios = axiosAuth(POLICY_SEARCH_URL);

export function SearchResults({ searchQuery, sort, from, size }: SearchResultsProps) {
    const documentQueryResult = getDocumentData({
        searchQuery: searchQuery,
        from: from,
        size: size,
        sort: sort,
    });

    // const [expanded, setExpanded] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<DocumentResult | undefined>();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentFrom, setCurrentFrom] = useState(0);
    const [additionalDocResults, setAdditionalDocResults] = useState<Array<DocumentResult>>([]);

    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };

    useEffect(() => {
        setAdditionalDocResults([]);
        setCurrentFrom(0);
    }, [searchQuery, sort]);

    // show card loading if data still loading
    if (documentQueryResult.isLoading || !documentQueryResult.data) {
        return <Skeleton sx={{ minHeight: 500, width: '100%' }} />;
    }

    const handleLoadMore = async () => {
        const newFrom = currentFrom + 10;

        setCurrentFrom(newFrom);

        const searchQueryToUse = Object.assign({}, searchQuery);

        const query = `${getRequestQuery(null, searchQueryToUse, newFrom, 10, sort, '/search/v1/research/search')}`;
        const result = await axios.get(query);

        const newDocResults = additionalDocResults.concat(result.data.hits.hits);

        setAdditionalDocResults(newDocResults);
    };

    const replaceHighlightFirst = (text: Array<string> | undefined) => {
        if (text && text.length > 0) {
            return replaceHighlight(text[0]);
        }

        return '';
    };

    const replaceHighlight = (text: string | undefined) => {
        if (text) {
            return text
                .replace(/<@highlight@>/g, "<span class='highlighted-text'>")
                .replace(/<\/@highlight@>/g, '</span>');
        }

        return '';
    };

    const handleDocumentClick = async (document: DocumentResult | undefined) => {
        if (document) {
            const searchQueryToUse = Object.assign({}, searchQuery);
            const documentFitler: KeywordFilter = { name: 'id', values: [document._source.id] };

            searchQueryToUse.keyword_filters = [documentFitler];

            const query = `${getRequestQuery(
                null,
                searchQueryToUse,
                null,
                1,
                null,
                '/search/v1/research/search',
            )}&single_document=1`;
            const result = await axios.get(query);

            setSelectedDocument(result.data.hits.hits[0]);
            setDialogOpen(true);
        }
    };

    const formatDate = (text: string) => {
        return new Date(
            parseInt(text.substring(0, 4)),
            parseInt(text.substring(4, 6)) - 1,
            parseInt(text.substring(6, 8)),
        ).toDateString();
    };

    const handleRequestPDF = async (s3_url: string) => {
        const result = await axios.get('/search/v1/research/pdf', { params: { q: s3_url } });
        window.open(result.data);
    };

    return (
        <Grid container sx={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
                <Box>{`Total Results: ${documentQueryResult.data.hits.total.value.toString()}${
                    documentQueryResult.data.hits.total.value === 10000 ? '+' : ''
                }  (${documentQueryResult.data.took}ms)`}</Box>
            </Grid>
            <Grid item xs={12}>
                {documentQueryResult.data &&
                    documentQueryResult.data.hits &&
                    documentQueryResult.data.hits.hits &&
                    documentQueryResult.data.hits.hits.concat(additionalDocResults).map((document) => (
                        <Card key={document._source.id}>
                            {/* <Card sx={{ maxWidth: 550 }}> */}
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{ bgcolor: blue[500] }}
                                        aria-label='Policy Document'
                                        title={document._source.document_type}>
                                        {document._source.document_type[0]}
                                    </Avatar>
                                }
                                title={
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <Box
                                                sx={{ cursor: 'pointer', paddingTop: '10px', fontSize: '1.2rem' }}
                                                onClick={() => handleDocumentClick(document)}>
                                                <Typography
                                                    className='search-results-panel'
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            replaceHighlightFirst(document.highlight?.parsed_title) ||
                                                            document._source.parsed_title ||
                                                            replaceHighlightFirst(document.highlight?.title) ||
                                                            document._source.title,
                                                    }}
                                                />{' '}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box display='flex' justifyContent='flex-end'>
                                                <IconButton
                                                    onClick={() => handleRequestPDF(document._source.s3_location)}>
                                                    <PictureAsPdfOutlinedIcon
                                                        sx={{ height: '1.3em', width: '1.3em', color: '#3078B5' }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                subheader={
                                    <Grid container>
                                        <Grid item xs={10}>
                                            {document._source.authors_names?.join(', ')}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box display='flex' justifyContent='flex-end'>
                                                {formatDate(document._source.publish_date)}
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    // <span>
                                    //     <span>{document._source.authors_names?.join(', ')}</span>
                                    //     <span style={{ float: 'right' }}>
                                    //         {formatDate(document._source.publish_date)}
                                    //     </span>
                                    // </span>
                                }
                            />
                            <CardContent sx={{ paddingTop: '0px' }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {document._source.verified_tickers?.map((ticker) => (
                                            <Chip
                                                key={ticker.tradingitem_id}
                                                label={ticker.ticker}
                                                title={ticker.names[0]}
                                                color='primary'
                                                variant='outlined'
                                            />
                                        ))}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box display='flex' justifyContent='flex-end' sx={{ flexWrap: 'wrap' }}>
                                            {document._source.topics?.map((topic) => (
                                                <Chip label={topic} key={topic} variant='outlined' />
                                            ))}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                        <Typography
                                            variant='body2'
                                            color='text.secondary'
                                            className='search-results-panel'
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    replaceHighlightFirst(document.highlight?.key_takeaways) ||
                                                    document._source.key_takeaways,
                                            }}></Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                    {document.highlight?.raw_text?.map((text) => (
                                        <Grid item xs={3} key={text}>
                                            <Paper
                                                elevation={10}
                                                sx={{
                                                    boxShadow:
                                                        '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);',
                                                }}>
                                                <Typography
                                                    variant='body2'
                                                    color='text.primary'
                                                    sx={{ padding: '10px' }}
                                                    className='search-results-panel'
                                                    // sx={{ whiteSpace: 'pre-wrap' }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceHighlight('...' + text + '...'),
                                                    }}></Typography>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                            {/* <CardActions disableSpacing>
                                <ExpandMore
                                    expand={expanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label='show more'>
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </CardActions>
                            <Collapse in={expanded} timeout='auto' unmountOnExit>
                                <CardContent>
                                    {document.highlight?.title?.map((title) => (
                                        <Typography paragraph>{title}</Typography>
                                    ))}

                                    {document.highlight?.raw_text?.map((text) => (
                                        <Typography paragraph>{text}</Typography>
                                    ))}
                                </CardContent>
                            </Collapse> */}
                        </Card>
                    ))}
            </Grid>
            <DocumentDialog open={dialogOpen} setOpen={setDialogOpen} document={selectedDocument} />
            {/* <Grid item xs={6}>
                <Box
                    className='search-results-panel'
                    sx={{ whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={{
                        __html: replaceHighlight(selectedDocument?.highlight?.raw_text[0]),
                    }}></Box>
            </Grid> */}
            <Box>
                <Button onClick={() => handleLoadMore()}>Load More</Button>
            </Box>
        </Grid>
    );
}

import { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ValueTypes, formatValue } from 'utils/valuesFormatter';
import './agGrid.scss';

type ColumnDefProps = {
    field: string;
    filter?: boolean;
    resizable?: boolean;
    flex?: number;
    maxWidth?: number;
};

export type columnDefs = Array<ColDef<ColumnDefProps> | ColGroupDef<ColumnDefProps>> | null;

export function getAgGridFormatter(formattingType: ValueTypes) {
    return function (params: ValueFormatterParams) {
        return String(
            formatValue({
                value: params.value,
                formattingType: formattingType,
            }),
        );
    };
}

export function AgGrid({
    columnDefs,
    rowsData,
    numberOfRowsToShow,
    getResizebleMinWidthForColumn,
    getCustomlyFlexColumnWidth,
}: {
    columnDefs: columnDefs;
    rowsData: Array<any>;
    numberOfRowsToShow?: number;
    getResizebleMinWidthForColumn: (key: string) => number;
    getCustomlyFlexColumnWidth: (
        columnName: string,
        minWidth: number,
        maxWidth: number,
        allColumnsNames: Array<string>,
    ) => number | undefined;
}) {
    const gridRef = useRef<AgGridReact<ColumnDefProps>>(null);
    const defaultMaxWidth = 350;
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(
        (): ColDef => ({
            sortable: true,
            filter: true,
            resizable: true,
            autoHeight: true,
            maxWidth: defaultMaxWidth,
        }),
        [],
    );

    const defaultRowHeight = 46;

    const columnIdsToInitialDefs = useRef<Record<string, Record<string, string | number>>>({});

    type colDefType = {
        colId: string;
        minWidth: number;
        maxWidth: number;
        headerName: string;
        field: string;
        width: number;
    };

    const fillInitialCollumnDefs = useCallback((keys: Array<string>) => {
        if (Object.keys(columnIdsToInitialDefs.current).length !== 0) return;
        const columnDefs = gridRef.current?.api.getColumnDefs();
        if (!columnDefs) return;
        (columnDefs as Array<colDefType>).forEach((columnDef) => {
            keys.forEach((key) => {
                if (!columnIdsToInitialDefs.current[columnDef.colId])
                    columnIdsToInitialDefs.current[columnDef.colId] = {};
                columnIdsToInitialDefs.current[columnDef.colId][key] = columnDef[key as keyof typeof columnDef];
            });
        });
    }, []);

    function setMaxResizeWidthForAllColumnsTo(maxWidth: number) {
        const columnDefs = gridRef.current?.api.getColumnDefs();
        if (!columnDefs) return;
        gridRef.current?.api.setColumnDefs(columnDefs.map((columnDef) => ({ ...columnDef, maxWidth: maxWidth })));
    }

    const setMinResizeWidthForAllColumns = useCallback(() => {
        const columnDefs = gridRef.current?.api.getColumnDefs() as Array<colDefType>;
        if (!columnDefs) return;
        gridRef.current?.api.setColumnDefs(
            columnDefs.map((columnDef) => ({
                ...columnDef,
                minWidth: getResizebleMinWidthForColumn(columnDef.headerName),
            })),
        );
    }, [getResizebleMinWidthForColumn]);

    const setCustomlyFlexWidths = useCallback(
        (allColumnsNames: Array<string>) => {
            const columnDefs = gridRef.current?.api.getColumnDefs() as Array<colDefType>;
            if (!columnDefs) return;
            gridRef.current?.api.setColumnDefs(
                columnDefs.map((columnDef) => {
                    const customColumnWidth = getCustomlyFlexColumnWidth(
                        columnDef.headerName,
                        columnDef.minWidth,
                        columnDef.maxWidth,
                        allColumnsNames,
                    );
                    if (customColumnWidth) {
                        return { ...columnDef, width: customColumnWidth };
                    } else return columnDef;
                }),
            );
        },
        [getCustomlyFlexColumnWidth],
    );

    const setInitiallColumnDefsForAllColumns = useCallback(
        (keys: Array<string>) => {
            const localColumnDefs = gridRef.current?.api.getColumnDefs();
            if (!localColumnDefs) return;
            gridRef.current?.api.setColumnDefs(
                (localColumnDefs as Array<colDefType>).map((localColumnDef) => {
                    keys.forEach((key) => {
                        const colDefProp = (columnDefs as Array<colDefType>).filter(
                            (item) => item.field === localColumnDef.field,
                        )[0];
                        localColumnDef = {
                            ...localColumnDef,
                            [key]: colDefProp.hasOwnProperty(key)
                                ? colDefProp[key as keyof typeof colDefProp]
                                : columnIdsToInitialDefs.current[localColumnDef.field][key],
                        };
                    });
                    return localColumnDef;
                }),
            );
        },
        [columnDefs],
    );

    function calculateAllColumnsWidths() {
        return (
            gridRef.current?.columnApi
                .getColumns()
                ?.reduce((prevResult, current) => (prevResult += current.getActualWidth()), 0) || 0
        );
    }

    const autoSizeAllColumns = useCallback(() => {
        if (!gridRef.current) return;
        const keys = ['maxWidth', 'minWidth'];
        fillInitialCollumnDefs(keys);
        setInitiallColumnDefsForAllColumns(keys);
        gridRef.current?.columnApi.autoSizeAllColumns(false);
        const right = gridRef.current.api.getHorizontalPixelRange().right;
        const actualWidthExceededViewableWidth = calculateAllColumnsWidths() > right;
        if (actualWidthExceededViewableWidth) {
            gridRef.current?.columnApi.autoSizeAllColumns(true);
        }

        setCustomlyFlexWidths(
            gridRef.current?.api.getColumnDefs()?.map((element) => element.headerName as string) || [],
        );
        setMaxResizeWidthForAllColumnsTo(right);
        setMinResizeWidthForAllColumns();
    }, [
        fillInitialCollumnDefs,
        setInitiallColumnDefsForAllColumns,
        setMinResizeWidthForAllColumns,
        setCustomlyFlexWidths,
    ]);

    return (
        <>
            <div style={{ width: '100%' }} className='cfra-ag-grid'>
                <div
                    className='ag-theme-alpine'
                    style={{
                        height: numberOfRowsToShow ? (numberOfRowsToShow + 1) * defaultRowHeight : undefined,
                    }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowsData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true}
                        rowSelection='single'
                        getRowHeight={() => defaultRowHeight}
                        headerHeight={defaultRowHeight}
                        onGridReady={() => setTimeout(() => autoSizeAllColumns(), 100)}
                        onGridSizeChanged={() => setTimeout(() => autoSizeAllColumns(), 100)} // onFirstDataRendered not always works without setTimeout
                        domLayout={numberOfRowsToShow ? undefined : 'autoHeight'}
                    />
                </div>
            </div>
        </>
    );
}

import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { DataAsOfDate, ETFCard, ETFEmptyCard } from 'components/layout';
import { getLevel2Performance } from 'features/marketTrends/api/market';
import { HomeProps, MarketTrend } from 'features/marketTrends/types/market';
import { ETFPerformanceThemesAndFactorsData } from 'features/marketTrends/types/research';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { PerformanceDateRanges, ValueTypes } from 'utils';
import { getColumnHeaderFormatter, ThemesAndFactorsChart, xAxisColumnName } from './shared/ThemesAndFactors';

import {
    themeCategories,
    performanceOrderFields,
    getChangeEvents,
    dataLimitForAssetCategory,
    mappingFunction,
    categoriesMapFunction,
} from './shared/ThemeFilters';

const localStorageID = 'market-trends-etf-performance-themes-and-factors-';

export default function ETFPerformanceThemesAndFactors({ details = true }: HomeProps) {
    const [highestChartRef, setHighestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [lowestChartRef, setLowestChartRef] = React.useState<React.RefObject<HighchartsReact.RefObject>>();
    const [currentActiveTab, setCurrentActiveTab] = useLocalStorage(
        localStorageID + 'active-tab',
        themeCategories.findIndex((item) => item.isdefault),
    );

    const [currentPeriod, setCurrentPeriod] = useLocalStorage(localStorageID + 'active-perod', '3M');

    const { handleTabChange, handlePeriodChange } = getChangeEvents(setCurrentActiveTab, setCurrentPeriod);

    const dataMapFunction = function (item: string) {
        return getLevel2Performance({
            sortDirection: item as 'desc' | 'asc',
            orderBy: performanceOrderFields.get(currentPeriod),
            aggregateBy: themeCategories[currentActiveTab].aggregateField,
            top: currentActiveTab === 0 ? dataLimitForAssetCategory : undefined,
        });
    };

    let chartData = ['desc', 'asc'].map(dataMapFunction);
    if (currentActiveTab === 0) chartData = [chartData[0]];

    // show card loading if data still loading
    if (chartData.find((data) => data.isLoading)) {
        return <ETFCard isLoading={true} />;
    }

    const cardTitle = 'Themes & Factors: Performance';
    // Show Nothing if no data
    if (!chartData.every((item) => item.data && item.data.length > 0)) {
        return <ETFEmptyCard cardLabel={cardTitle}></ETFEmptyCard>;
    }

    // cut off UseQueryResult attributes, extract only KeyAssetClasses data
    const yAxisColumnName = 'Performance';

    const columnsToFormatting = new Map<string, ValueTypes>();
    columnsToFormatting.set(xAxisColumnName, ValueTypes.Text);
    columnsToFormatting.set(yAxisColumnName, ValueTypes.Numeral);

    const mapFunction = mappingFunction(performanceOrderFields);

    const getChartData = () => {
        return chartData.map((item, index) => {
            return {
                chartHeader: currentActiveTab === 0 ? 'Assets' : (index === 0 ? 'Best' : 'Worst') + ' Performing',
                categories:
                    item.data?.map((value) =>
                        categoriesMapFunction<ETFPerformanceThemesAndFactorsData>({ value, currentActiveTab }),
                    ) || [],
                categoriesData:
                    item.data?.map((value) =>
                        mapFunction<ETFPerformanceThemesAndFactorsData>({ value, currentPeriod }),
                    ) || [],
                asOfDates: item.data?.map((value) => value.as_of_date) || [],
                tickers: item.data?.map((value) => value.composite_ticker) || [],
                chartRefObject: index === 0 ? highestChartRef : lowestChartRef,
                chartRefSetter: index === 0 ? setHighestChartRef : setLowestChartRef,
                columnsToFormatting: columnsToFormatting,
            };
        });
    };

     const asOfDate = chartData
         .flatMap((item) => item.data)
         .map((item) => item?.as_of_date)
         .sort()
         .reverse()[0] || '';

    return (
        <ETFCard containerStyles={{ paddingBottom: '16px', position: 'relative' }}>
            <ThemesAndFactorsChart
                title={cardTitle}
                subHeader={'ETF categories with the highest and lowest trailing total returns for each time period'}
                tooltipFormatterValuePrefix={`${yAxisColumnName}:`}
                columnHeaderFormatter={getColumnHeaderFormatter(yAxisColumnName)}
                exportFileName={`performance-themes-and-factors-${currentPeriod}`}
                detailsPathName={details ? MarketTrend.PerformanceDetails : undefined}
                subChartsProps={getChartData()}
                chartFilters={{
                    data: {
                        periods: Object.values(PerformanceDateRanges),
                        categories: themeCategories,
                    },
                    eventHandlers: {
                        tabChange: handleTabChange,
                        periodChange: handlePeriodChange,
                    },
                    selections: {
                        period: currentPeriod,
                        tab: currentActiveTab,
                    },
                }}
            />
            <DataAsOfDate date={asOfDate} />
        </ETFCard>
    );
}

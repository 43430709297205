import { Grid, useMediaQuery, useTheme } from '@mui/material';
import TrendingUpCircle from 'assets/images/TrendingUpCircle.svg';
import { ETFHeaderContainer, ETFPageContainer, ETFTabContainer, StyledTab, StyledTabs } from 'components/layout';
import { ETFDivider } from 'components/layout/ETFDivider';
import { throttle } from 'lodash';
import { Children, useContext, useEffect, useRef } from 'react';
import {
    FormatValueParams,
    assetClasses,
    formatValue,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNameToFormattingType,
    getDataPointsDisplayNames,
} from 'utils';
import { Component, TabName } from '../types/components';
import { ETFDataAllRangesQueryResult, ETFDetailsParams, EtfDetailsData } from '../types/research';
import CreditExposure from './CreditExposure';
import CreditExposureHistorical from './CreditExposureHistorical';
import ETFFlows, { getChartDataAllRanges } from './ETFFlows';
import ETFFlowsVSMarketMovement from './ETFFlowsVSMarketMovement';
import ETFPerformance from './ETFPerformance';
import FundDetail from './FundDetail';
import KeyStatistic from './KeyStatistic';
import MaturityExposure from './MaturityExposure';
import FundProfile from './Overview';
import SectorExposure from './SectorExposure';
import SectorExposureHistorical from './SectorExposureHistorical';
import TopHoldings from './TopHoldings';
import { ScrollingContext, animateScrollTo, animateScrollToTop } from 'utils/scrolling';

export default function ETFDetailTabs({
    latestUpdatesQuery,
    companyData,
}: {
    latestUpdatesQuery: EtfDetailsData;
    companyData: ETFDetailsParams;
}) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const ref = useRef<HTMLDivElement[]>([]);

    const { goingUp, setGoingUp, activeTab, setActiveTab } = useContext(ScrollingContext);

    const prevScrollY = useRef(0);

    const onScroll = () => {
        const currentScrollY = window.scrollY;

        if (prevScrollY.current < currentScrollY && goingUp) {
            setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
            setGoingUp(true);
        }

        const { top, height } = ref.current[Number(matches)].getBoundingClientRect();
        const absoluteElementCenter = top + height / 2 + window.pageYOffset;
        const middle = Math.floor(absoluteElementCenter - window.innerHeight / 2);

        if (middle < currentScrollY && !goingUp && activeTab !== 1) {
            setActiveTab(1);
        }
        if (middle >= currentScrollY && goingUp && activeTab !== 0) {
            setActiveTab(0);
        }

        prevScrollY.current = currentScrollY;
    };

    var throttledFunc = throttle(onScroll, 100, { leading: true });

    useEffect(() => {
        window.addEventListener('scroll', throttledFunc, { passive: true });
        return () => {
            window.removeEventListener('scroll', throttledFunc);
        };
    }, [activeTab, goingUp, throttledFunc]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const scrollToTop = () => {
        animateScrollToTop();
        setGoingUp(true);
    };

    const scrollToDivider = () => {
        const { top, height } = ref.current[Number(matches)].getBoundingClientRect();
        const absoluteElementCenter = top + height / 2 + window.pageYOffset;
        const middle = Math.floor(absoluteElementCenter - window.innerHeight / 2);
        animateScrollTo(middle);
        if (window.scrollY < middle) setGoingUp(false);
        else setGoingUp(true);
    };

    // get display names list for all data points
    const dataPointsDisplayNames = getDataPointsDisplayNames();
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName();
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType();
    // create instance of class FormatValueParams
    const formatValueParams = new FormatValueParams({
        source: latestUpdatesQuery,
        dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
    });

    const cfraId = formatValue(formatValueParams.create({ key: dataPointsDisplayNames.CfraID })) as string;
    const etfDataAllRangesQueryResult: ETFDataAllRangesQueryResult = getChartDataAllRanges(cfraId);

    const getRenderedDivider = (ref: (element: HTMLDivElement) => void) => (
        <ETFDivider
            imageSource={TrendingUpCircle}
            header='Flows and Performance'
            description='The charts below highlight historical net flows, total returns, and an analysis of fund asset level changes over time.'
            ref={ref}
        />
    );

    const componentMapping: Record<Component, JSX.Element> = {
        [Component.Overview]: <FundProfile etfDetailsData={latestUpdatesQuery} />,
        [Component.SectorExposureOverTime]: <SectorExposureHistorical companyData={companyData} />,
        [Component.SectorExposure]: <SectorExposure cfraId={cfraId} companyData={companyData} />,
        [Component.TopHoldings]: <TopHoldings etfDetailsData={latestUpdatesQuery} cfraId={cfraId} />,
        [Component.MaturityExposure]: <MaturityExposure cfraId={cfraId} companyData={companyData} />,
        [Component.CreditExposure]: <CreditExposure cfraId={cfraId} companyData={companyData} />,
        [Component.CreditExposureOvertime]: <CreditExposureHistorical companyData={companyData} />,
        [Component.SectionDividerFlowsPerformance]: getRenderedDivider((element) => {
            if (element) ref.current[0] = element;
        }),
        [Component.FundFlows]: (
            <ETFFlows etfDataAllRangesQueryResult={etfDataAllRangesQueryResult} companyData={companyData} />
        ),
        [Component.FundVsMarketMovement]: (
            <ETFFlowsVSMarketMovement
                etfDataAllRangesQueryResult={etfDataAllRangesQueryResult}
                companyData={companyData}
            />
        ),
        [Component.ETFPerformance]: <ETFPerformance etfDetailsData={latestUpdatesQuery} cfraId={cfraId} />,
        [Component.KeyStatistics]: <KeyStatistic etfDetailsData={latestUpdatesQuery} />,
        [Component.FundDetails]: <FundDetail detail={latestUpdatesQuery.etf_description} />,
    };

    const defaultSingleColumnLayout: Record<TabName, Component[]> = {
        [TabName.HoldingsAndExposure]: [
            Component.FundDetails,
            Component.TopHoldings,
            Component.SectorExposureOverTime,
            Component.SectorExposure,
        ],
        [TabName.FlowsAndPerformance]: [
            Component.FundFlows,
            Component.FundVsMarketMovement,
            Component.KeyStatistics,
            Component.ETFPerformance,
            Component.Overview,
        ],
    };

    const defaultMultipleColumnsLayout: Record<number, Record<TabName, Component[]>> = {
        0: {
            [TabName.HoldingsAndExposure]: [
                Component.TopHoldings,
                Component.SectorExposureOverTime,
                Component.SectorExposure,
                Component.SectionDividerFlowsPerformance,
                Component.ETFPerformance,
                Component.FundFlows,
            ],
            [TabName.FlowsAndPerformance]: [],
        },
        1: {
            [TabName.HoldingsAndExposure]: [Component.Overview, Component.KeyStatistics, Component.FundDetails],
            [TabName.FlowsAndPerformance]: [],
        },
    };

    const singleColumnLayout: Record<TabName, { [key in assetClasses]?: Component[] }> = {
        [TabName.HoldingsAndExposure]: {
            [assetClasses.EquitiesStocks]: [
                Component.FundDetails,
                Component.SectorExposureOverTime,
                Component.SectorExposure,
                Component.TopHoldings,
            ],
            [assetClasses.Bonds]: [
                Component.FundDetails,
                Component.CreditExposureOvertime,
                Component.CreditExposure,
                Component.MaturityExposure,
                Component.TopHoldings,
            ],
        },
        [TabName.FlowsAndPerformance]: {
            [assetClasses.EquitiesStocks]: [
                Component.FundFlows,
                Component.FundVsMarketMovement,
                Component.ETFPerformance,
                Component.KeyStatistics,
                Component.Overview,
            ],
            [assetClasses.Bonds]: [
                Component.FundFlows,
                Component.FundVsMarketMovement,
                Component.KeyStatistics,
                Component.ETFPerformance,
                Component.Overview,
            ],
        },
    };

    const multipleColumnsLayout: Record<number, Record<TabName, { [key in assetClasses]?: Component[] }>> = {
        0: {
            [TabName.HoldingsAndExposure]: {
                [assetClasses.EquitiesStocks]: [
                    Component.SectorExposureOverTime,
                    Component.SectorExposure,
                    Component.TopHoldings,
                ],
                [assetClasses.Bonds]: [
                    Component.CreditExposureOvertime,
                    Component.CreditExposure,
                    Component.MaturityExposure,
                    Component.TopHoldings,
                ],
            },
            [TabName.FlowsAndPerformance]: {
                [assetClasses.EquitiesStocks]: [Component.FundFlows],
                [assetClasses.Bonds]: [Component.FundFlows],
            },
        },
        1: {
            [TabName.HoldingsAndExposure]: {
                [assetClasses.EquitiesStocks]: [Component.Overview, Component.KeyStatistics, Component.FundDetails],
            },
            [TabName.FlowsAndPerformance]: {
                [assetClasses.EquitiesStocks]: [Component.ETFPerformance],
                [assetClasses.Bonds]: [Component.ETFPerformance],
            },
        },
    };

    const singleColumnComponents = (tabName: TabName, assetClass: assetClasses) => {
        return Children.toArray(
            (singleColumnLayout[tabName][assetClass] ?? defaultSingleColumnLayout[tabName]).map(
                (component: Component) => componentMapping[component],
            ),
        );
    };

    const multipleColumnsComponents = (tabName: TabName, assetClass: assetClasses, column: number) => {
        return Children.toArray(
            (multipleColumnsLayout[column][tabName][assetClass] ?? defaultMultipleColumnsLayout[column][tabName]).map(
                (component: Component) => componentMapping[component],
            ),
        );
    };

    return (
        <>
            <ETFHeaderContainer
                shadow
                sx={{
                    zIndex: 1100,
                    position: 'sticky',
                    top: '135px',
                }}>
                <StyledTabs value={activeTab} onChange={handleChange} aria-label='etf tabs'>
                    <StyledTab label={TabName.HoldingsAndExposure} value={0} onClick={scrollToTop} />
                    {/* getSection() function on etf details page have dependency on FlowsAndPerformance tab */}
                    <StyledTab label={TabName.FlowsAndPerformance} value={1} onClick={scrollToDivider} />
                </StyledTabs>
            </ETFHeaderContainer>
            <ETFPageContainer>
                <ETFTabContainer>
                    {matches ? (
                        <Grid container spacing={3}>
                            <Grid container item xs={12}>
                                {singleColumnComponents(TabName.HoldingsAndExposure, latestUpdatesQuery.asset_class)}
                                {getRenderedDivider((element) => {
                                    if (element) ref.current[1] = element;
                                })}
                                {singleColumnComponents(TabName.FlowsAndPerformance, latestUpdatesQuery.asset_class)}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                {multipleColumnsComponents(
                                    TabName.HoldingsAndExposure,
                                    latestUpdatesQuery.asset_class,
                                    0,
                                )}
                            </Grid>
                            <Grid item md={4}>
                                {multipleColumnsComponents(
                                    TabName.HoldingsAndExposure,
                                    latestUpdatesQuery.asset_class,
                                    1,
                                )}
                            </Grid>
                            {[assetClasses.EquitiesStocks, assetClasses.Bonds].includes(
                                latestUpdatesQuery.asset_class,
                            ) && (
                                <Grid item md={12}>
                                    {componentMapping[Component.SectionDividerFlowsPerformance]}
                                </Grid>
                            )}
                            <Grid item md={8}>
                                {multipleColumnsComponents(
                                    TabName.FlowsAndPerformance,
                                    latestUpdatesQuery.asset_class,
                                    0,
                                )}
                            </Grid>
                            <Grid item md={4}>
                                {multipleColumnsComponents(
                                    TabName.FlowsAndPerformance,
                                    latestUpdatesQuery.asset_class,
                                    1,
                                )}
                            </Grid>
                            <Grid item md={12}>
                                {componentMapping[Component.FundVsMarketMovement]}
                            </Grid>
                        </Grid>
                    )}
                </ETFTabContainer>
            </ETFPageContainer>
        </>
    );
}

import { Box, Stack, styled } from "@mui/material";
import { Button } from "components/ETFButton";
import Typography from "components/dataDisplay/Typography";
import Grid from "components/layout/Grid";
import { fontFamilies } from "utils";

type RelatedResources = {
    title: string;
    description: string;
    pdfUrl: string;
}

type Research = {
    image: string;
    title: string;
    description: string;
    pdfUrl: string;
}

type LatestResearchProps = {
    latestResearch: Research;
    relatedResources: RelatedResources[];
}

export function LatestResearch(props: LatestResearchProps) {
    const { latestResearch, relatedResources } = props;

    const RoundedButton = styled(Button)({
        padding: '14px',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
        borderRadius: '25px',
        color: '#fff',
        fontFamily: fontFamilies.GraphikBold,
        backgroundColor: '#007BB8',
        borderColor: '#007BB8',
        width: '10rem',
        '&:hover': {
            backgroundColor: '#002B5A',
            borderColor: '#002B5A',
        },
    });

    const RelatedResourcesBackground = styled(Box)(({ theme }) => ({
        position: 'absolute',
        background: '#F2F5FC',
        height: '100%',
        top: 0,
        left: 0,
        width: 'calc(100%  + max(0px,calc((100vw - 1366px)/2)))',
        borderRadius: '8px 0 0 8px',
        '@media (max-width: 1486px)': {
            width: 'calc(100%  + 60px)',
        },
        [theme.breakpoints.only('xs')]: {
            width: 'calc(100% + 24px)',
        },
    }));

    const RelatedResourcesContainer = styled(Box)(({ theme }) => ({
        padding: '52px',
        paddingRight: '0',
        position: 'relative',
        [theme.breakpoints.only('xs')]: {
            padding: '36px',
            paddingRight: '0',
        },
    }));

    const viewPdf = (pdfUrl: string) => {
        window.open(pdfUrl, '_blank')
    }

    return (
        <Grid container spacing={6}>
            <Grid item sm={12} lg={8}>
                <Typography variant="h5" textAlign='left' marginBottom='28px'>LATEST RESEARCH</Typography>
                <Grid container spacing={6}>
                    <Grid item sm={12} md={6} width='100%'>
                        <Box component='img' src={latestResearch.image} alt="latest research image" width='100%' height='auto' />
                    </Grid>
                    <Grid item sm={12} md={6} width='100%'>
                        <Stack spacing={3}>
                            <Typography
                                variant="h4"
                                sx={{ textAlign: 'left', cursor: 'pointer' }}
                                onClick={() => viewPdf(latestResearch.pdfUrl)}>
                                {latestResearch.title}
                            </Typography>

                            <Typography variant="body2" textAlign='left'>{latestResearch.description}</Typography>
                            <RoundedButton onClick={() => viewPdf(latestResearch.pdfUrl)}>Download</RoundedButton>
                        </Stack>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item sm={12} lg={4} display='grid' width='100%'>
                <Box position='relative'>
                    <RelatedResourcesBackground />
                    <RelatedResourcesContainer>
                        <Typography variant="h5" textAlign='left' marginBottom='28px'>RELATED RESOURCES</Typography>
                        <Stack spacing={3.5}>
                            {relatedResources.map((resources) => (
                                <Box key={resources.title} component='div'>
                                    <Typography
                                        variant="h6"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => viewPdf(resources.pdfUrl)}>
                                        {resources.title}
                                    </Typography>

                                    <Typography variant="subtitle2" lineHeight='24px' >{resources.description}</Typography>
                                </Box>
                            ))}
                        </Stack>
                    </RelatedResourcesContainer>
                </Box>
            </Grid>
        </Grid>
    );
};